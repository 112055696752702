var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ai-video"
  }, [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          attrs: {
            size: "large",
            type: "primary"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-row", {
    staticStyle: {
      margin: "20px 0",
      "text-align": "right"
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showModel
    }
  }, [_vm._v("配置")])], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      borderd: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading,
      scroll: {
        x: "100%"
      }
    },
    scopedSlots: _vm._u([{
      key: "videourl",
      fn: function fn(videourl, row) {
        return [_c("a", {
          attrs: {
            href: videourl,
            target: "_blank"
          }
        }, [_vm._v(_vm._s(videourl))])];
      }
    }, {
      key: "content",
      fn: function fn(content, row) {
        return [_c("div", {
          staticClass: "text-line-two"
        }, [_vm._v(_vm._s(content))])];
      }
    }, {
      key: "createdate",
      fn: function fn(createdate, row) {
        return [_c("span", [_vm._v(_vm._s(_vm.dateFormatFunc(createdate * 1000, "d M, Y H:i:s")))])];
      }
    }, {
      key: "action",
      fn: function fn(actions, row) {
        return [_c("div", [_c("a-col", [_c("a", {
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.viewHandler(row);
            }
          }
        }, [_vm._v("查看")])])], 1)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      showQuickJumper: false,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "配置",
      footer: null
    },
    model: {
      value: _vm.visibleConfig,
      callback: function callback($$v) {
        _vm.visibleConfig = $$v;
      },
      expression: "visibleConfig"
    }
  }, [_c("a-form-model", {
    ref: "configForm",
    staticClass: "mt-20",
    attrs: {
      model: _vm.configFormData,
      rules: _vm.rules,
      labelCol: {
        span: 9
      },
      labelAlign: "left"
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "User daily allowance",
      prop: "dailyallowance"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.configFormData.dailyallowance,
      callback: function callback($$v) {
        _vm.$set(_vm.configFormData, "dailyallowance", _vm._n($$v));
      },
      expression: "configFormData.dailyallowance"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "Platform daily allowance",
      prop: "platformallowance"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.configFormData.platformallowance,
      callback: function callback($$v) {
        _vm.$set(_vm.configFormData, "platformallowance", _vm._n($$v));
      },
      expression: "configFormData.platformallowance"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "Cost to each conversion",
      prop: "costtoeachconversion"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.configFormData.costtoeachconversion,
      callback: function callback($$v) {
        _vm.$set(_vm.configFormData, "costtoeachconversion", _vm._n($$v));
      },
      expression: "configFormData.costtoeachconversion"
    }
  })], 1), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("提交")])], 1)], 1), _c("a-modal", {
    attrs: {
      title: "查看",
      footer: null
    },
    model: {
      value: _vm.visibleView,
      callback: function callback($$v) {
        _vm.visibleView = $$v;
      },
      expression: "visibleView"
    }
  }, [_c("a-card", [_c("a-card-grid", {
    staticClass: "item"
  }, [_c("a-col", {
    staticClass: "item-title",
    attrs: {
      span: 6
    }
  }, [_vm._v("AI视频ID")]), _c("a-col", {
    staticClass: "item-value",
    attrs: {
      span: 18
    }
  }, [_vm._v(_vm._s(_vm.viewItem.aivideoid))])], 1), _c("a-card-grid", {
    staticClass: "item"
  }, [_c("a-col", {
    staticClass: "item-title",
    attrs: {
      span: 6
    }
  }, [_vm._v("Fliki ID")]), _c("a-col", {
    staticClass: "item-value",
    attrs: {
      span: 18
    }
  }, [_vm._v(_vm._s(_vm.viewItem.flikiid))])], 1), _c("a-card-grid", {
    staticClass: "item"
  }, [_c("a-col", {
    staticClass: "item-title",
    attrs: {
      span: 6
    }
  }, [_vm._v("视频链接")]), _c("a-col", {
    staticClass: "item-value",
    attrs: {
      span: 18
    }
  }, [_c("a", {
    staticStyle: {
      color: "#1890ff"
    },
    attrs: {
      target: "_blank"
    }
  }, [_vm._v(_vm._s(_vm.viewItem.videourl))])])], 1), _c("a-card-grid", {
    staticClass: "item"
  }, [_c("a-col", {
    staticClass: "item-title",
    attrs: {
      span: 6
    }
  }, [_vm._v("输入文案")]), _c("a-col", {
    staticClass: "item-value",
    attrs: {
      span: 18
    }
  }, [_vm._v(_vm._s(_vm.viewItem.content))])], 1), _c("a-card-grid", {
    staticClass: "item"
  }, [_c("a-col", {
    staticClass: "item-title",
    attrs: {
      span: 6
    }
  }, [_vm._v("消费CBK")]), _c("a-col", {
    staticClass: "item-value",
    attrs: {
      span: 18
    }
  }, [_vm._v(_vm._s(_vm.viewItem.amount))])], 1), _c("a-card-grid", {
    staticClass: "item"
  }, [_c("a-col", {
    staticClass: "item-title",
    attrs: {
      span: 6
    }
  }, [_vm._v("消费用户昵称")]), _c("a-col", {
    staticClass: "item-value",
    attrs: {
      span: 18
    }
  }, [_vm._v(_vm._s(_vm.viewItem.username))])], 1), _c("a-card-grid", {
    staticClass: "item"
  }, [_c("a-col", {
    staticClass: "item-title",
    attrs: {
      span: 6
    }
  }, [_vm._v("创建时间")]), _c("a-col", {
    staticClass: "item-value",
    attrs: {
      span: 18
    }
  }, [_vm._v(_vm._s(_vm.dateFormatFunc(_vm.viewItem.createdate * 1000, "d M, Y H:i:s")))])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };